import axios from "axios";
import { useMutation } from "react-query";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<string> => {
  try{
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/auth`, { username:email, password });

    return data.body
  }catch(err){
    console.log(err);
    throw err
  }
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return { isLoggingIn: isLoading, login: mutateAsync };
}
