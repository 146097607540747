import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const CompletedUsers = lazy(() => import("./admin/pages/CompletedUsers"));
const InactiveUsers = lazy(() => import("./admin/pages/InactiveUsers"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileBackups"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileEmails")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));


const Login = lazy(() => import("./auth/pages/Login"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));

// Users
const UserManagement = lazy(() => import("./users/pages/UserManagement"));

const AppRoutes = () => {
  return (
    <Routes >
      <PrivateRoute path="/" element={<Admin />} >
        <PrivateRoute path="/" element={<UserManagement />} />
        <PrivateRoute path="/inactive" element={<InactiveUsers />} />
        <PrivateRoute path="completeusers" element={<CompletedUsers />} />
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileActivity />} />
          <PrivateRoute path="information" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
        </PrivateRoute>
      </PrivateRoute>
      <Route path="login" element={<Login />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route
        path="*"
        element={<Navigate to={`/404`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
